import { navigate } from "gatsby-link";
import { useEffect } from "react";

const IntegrationGuide = () => {
    useEffect(() => {
        navigate("/setup-guide");
    });

    return null;
};

export default IntegrationGuide;